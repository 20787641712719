export const context = {
    active: window.location.hash === "#contact-us" ? 6 : 0,
    ids: null,
    controller: null,
    childrenArray: null,
    sections: null,
    prevsections: null,
    changing: false,
    globalScrollHeight: 0,
    externalChange: false,
    setExternalChanging: null,
    wheel: 0,
    wheelTo: 0,
    activeForm: false,
};
